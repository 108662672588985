import styles from "./Navbar.module.css";
import logo from "../../assets/logo/i4S_logo.png";
import { useMediaQuery } from "react-responsive";
import { FiMenu, FiX } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isBtnClick, setBtnClick] = useState(false);

  const isMobileOn = useMediaQuery({
    query: "(max-width: 786px)",
  });

  return (
    <>
      {isMobileOn ? (
        isBtnClick ? (
          <header>
            <FiX
              className={styles.icon}
              onClick={() => {
                setBtnClick(!isBtnClick);
              }}
            />
          </header>
        ) : (
          <header>
            <a href="/" className={styles.logo}>
              <img
                className={styles["company-logo"]}
                src={logo}
                alt="I4S company logo"
              />
            </a>
            <FiMenu
              className={styles.icon}
              onClick={() => {
                setBtnClick(!isBtnClick);
              }}
            />
          </header>
        )
      ) : (
        <header>
          <div className={styles.container}>
            <nav>
              <Link
                to="/"
                className={styles.logo}
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and
                }}
              >
                <img
                  className={styles["company-logo"]}
                  src={logo}
                  alt="I4S company logo"
                />
              </Link>
              <ul className={styles["nav-lists"]}>
                <li>
                  <Link
                    to="/#Home"
                    onClick={() => {
                      setTimeout(function () {
                        document.getElementById("Home").scrollIntoView();
                        window.scroll(0, -200);
                      }, 10);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#About"
                    onClick={() => {
                      setTimeout(function () {
                        document.getElementById("About").scrollIntoView();
                      }, 10);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#Services"
                    onClick={() => {
                      setTimeout(function () {
                        document.getElementById("Services").scrollIntoView();
                      }, 10);
                    }}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#Contact"
                    onClick={() => {
                      setTimeout(function () {
                        document.getElementById("Contact").scrollIntoView();
                      }, 10);
                    }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      )}
      {isBtnClick ? (
        <div className={`${styles.container}  ${styles["nav-open"]}`}>
          <nav>
            <ul className={styles["nav-lists"]}>
              <li className={styles["nav-list"]}>
                <a
                  onClick={() => {
                    setBtnClick(!isBtnClick);
                    setTimeout(function () {
                      document.getElementById("Home").scrollIntoView();
                      window.scroll(0, -200);
                    }, 10);
                  }}
                  href="/#Home"
                >
                  Home
                </a>
              </li>
              <li className={styles["nav-list"]}>
                <a onClick={() => setBtnClick(!isBtnClick)} href="#About">
                  About
                </a>
              </li>
              <li className={styles["nav-list"]}>
                <a onClick={() => setBtnClick(!isBtnClick)} href="#Services">
                  Services
                </a>
              </li>
              <li className={styles["nav-list"]}>
                <a onClick={() => setBtnClick(!isBtnClick)} href="#Contact">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      ) : null}
    </>
  );
}
