import styles from "./ContactUs.module.css";
import contactPageVector from "../../assets/icons/vector_contact.png";
import Container from "../UI/Container";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function ContactUs() {
  const form = useRef();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "service_y97xpfl",
        "template_foewxud",
        form.current,
        "27oMExaP8SMToAwqk"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    alert("mail submitted");
  };

  return (
    <section id="Contact" className="contact-us">
      <Container className="container">
        <h3 className={styles["section-title"]}>Contact Us</h3>
        <div className={styles["container-secondary"]}>
          <div className={styles["image-container"]}>
            <img
              src={contactPageVector}
              alt="vector of a women with a mobile and computer"
            />
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className={styles["form-container"]}
          >
            <div className="name">
              <label htmlFor="name">Name</label>
              <input
                required
                name="user_name"
                type="text"
                id="name"
                placeholder="Your Name"
              />
            </div>
            <div className="e-mail">
              <label htmlFor="email">E-mail</label>
              <input
                required
                name="user_email"
                type="email"
                id="email"
                placeholder="Your E-mail Address"
              />
            </div>
            <div className="number">
              <label htmlFor="number">Number</label>
              <input
                name="user_number"
                type="number"
                id="number"
                placeholder="Your Number"
              />
            </div>
            <div className="message">
              <label htmlFor="textarea">Message</label>
              <textarea
                required
                name="user_message"
                id="textarea"
                cols="30"
                rows="10"
                placeholder="Your Message..."
                style={{ height: "10rem" }}
              />
            </div>
            <button className={styles["btn-submit"]} type="submit" value="send">
              Submit
            </button>
          </form>
        </div>
      </Container>
    </section>
  );
}
