import styles from "./LandingPage.module.css";
import landingPageVector from "../../assets/icons/vector_landing_page1.png";
import Container from "../UI/Container";
import { useMediaQuery } from "react-responsive";

export default function LandingPage() {
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <section id="Home" className="landing-page">
      <Container className="container">
        <div className={styles["flex-container"]}>
          <div className={styles["image-container"]}>
            <img
              src={landingPageVector}
              alt="vector image of a helping partner"
            />
          </div>
          <div className={styles["content-container"]}>
            {isMobile ? (
              <h1 className={styles["title-primary"]}>
                i4S
                <br />
                <span>Technologies</span>
              </h1>
            ) : (
              <h1 className={styles["title-primary"]}>
                i4S
                <br />
                <span>Technologies</span>
              </h1>
            )}
            <h2 className={styles["title-secondary"]}>
              Future of Infrastructure
            </h2>
            <a href="#Contact">
              <button type="button" className={styles["btn-contact"]}>
                Contact
              </button>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
}
