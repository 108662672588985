import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Container from "../UI/Container";
import styles from "./Privacy.module.css";

export default function Privacy() {
  return (
    <>
      <section id="Privacy-policy">
        <Container className="container">
          <h3 className={styles["section-title"]}>Privacy Policy</h3>
          <h4 className={styles["title"]}>About this privacy notice</h4>
          <p className={styles["paragraph"]}>
            This is the privacy notice of i4Sight Technologies / Towerbuddy
            ("i4Sight Technologies" / "Towerbuddy" , "we", "us", "our"). i4Sight
            Technologies / Towerbuddy is the data controller for the purpose of
            this Notice. This Notice, together with our www.i4Sight Technologies
            / Towerbuddy.com/terms-of-use/, sets out the basis on which we will
            process any personal data obtained in connection with respect to
            your use of and interaction with this website or any other online
            presence administered by us including our social media presences and
            our applications, (collectively referred to as "Website"). For
            information about our use of cookies, pixels and social plug-ins,
            please see our Notice regarding Cookies, Pixels and Social Plug-ins.
            We may post any changes we make to our Notice on this Website or
            communicate them to you by email. Any modifications to this privacy
            policy shall be notified to you through email. However, as a user of
            the website, you shall have an equal responsibility to make note of
            any of the said modifications, made to this document on a timely
            basis. The current version of this document was last changed on the
            15th of August, 2022.
          </p>
          <h4 className={styles["title"]}>
            What personal data do we collect from you?
          </h4>
          <p className={styles["paragraph"]}>
            We collect personally identifiable data that you explicitly provide
            to us, when you undertake actions, such as filling out form(s) of
            any kind with some examples such as a contact form, registering an
            account on the website, creating your user profile (if applicable)
            etc. In addition to it, we also collect associated information when
            you agree to participate in marketing promotions, requesting
            additional information, completing periodic survey(s) etc. either
            through the website, or through our advertising and partners
            providing the necessary service(s). Such personal data may consist
            of all/or most of the following components: Contact information:
            Anything that can be used and associated with personally identifying
            a user such as: first, middle and last name, postal address, email
            address, and mobile or other telephone number(s). Purchase and
            transactional information if applicable: Payment information (such
            as your payment card number, expiration date, authorization number
            or security code, delivery address, and billing address); Customer
            service information (such as customer service inquiries, comments,
            conversation, and transaction history); Information regarding your
            personal or professional interests, demographics, and experiences
            with our products and contact preferences; Any content (either
            physical or digital) such as photographs, images, comments and other
            content you provide. Contact information you provide about friends
            or other people you would like us to contact; and Information we may
            obtain from our third-party service providers. We collect metadata,
            for example details of your visits to the Website, such as location
            data, browser information, session data, preferences, settings,
            weblogs and other communication data, which we monitor during your
            interaction with the Website.
          </p>
          <h4 className={styles["title"]}>
            On which basis do we process your personal data?
          </h4>
          <p className={styles["paragraph"]}>
            We process your personal data for the purposes indicated or obvious
            at the time of collection and To which you have agreed to, for
            example by checking a box; or For which we are required by
            applicable law(s) in any/every country that we are operating in, for
            example to comply with data retention requirements regarding data
            relevant for financial reporting; or Which is necessary for the
            performance of a contract, for example if you order items; or For
            which we rely on other legitimate interests, which include:
            gathering market intelligence, promoting products and services,
            communicating with and tailoring offers to you; delivering and
            improving our products or services; management of customer, client,
            vendor and other relationships, sharing intelligence with internal
            stakeholders, implementing safety procedures, and planning and
            allocation of resources and budget; monitoring, detecting and
            protecting the organization, its systems, network, infrastructure,
            computers, information, intellectual property and other rights from
            unwanted security intrusion, unauthorized access, disclosure and
            acquisition of information, data and system breaches, hacking,
            industrial espionage and cyberattacks; protecting and developing
            industry standards; sharing intelligence about individuals or
            concerns that may have a negative or detrimental impact; and
            following industry best practices; or complying with industry
            standards, regulatorsâ€™ requirements and other requirements related
            to fraud prevention and anti-money laundering.
          </p>
          <h4 className={styles["title"]}>
            For which purposes do we process your personal data?
          </h4>
          <p className={styles["paragraph"]}>
            We process your personal data for the following purposes: If you
            fill out a contact form providing your name, email-address,
            telephone number, preferences, etc., we use your data for answering
            your questions, sending you information or performing other tasks
            you ask us to. If you register an account with us, then we process
            the data required to open that account, for example your name,
            address, email-address, password, profile picture, third party
            account data (if you register using your Facebook account, for
            example), etc. to allow your registration and management of your
            account. If you use our interactive features, we process the data
            required to use these features to allow you and manage their use,
            for example, your name and email-address to appear in a ranking and
            to send you updates regarding the online game in which you may have
            participated. If you are an existing customer, we may send you
            advertisement about our products and services using, for example,
            your name and postal address. If you subscribe to a service like our
            newsletter by providing us with your email-address and name, then we
            use this data for sending you our newsletter. If you participate in
            a marketing promotion, then we use the data we require from you to
            allow your participation, for example, to manage your invitation for
            an event or to determine and contact the winner of a contest. If you
            answer one of our queries and provide us personal data such as name,
            age, occupation, etc., then we use this data to analyze the query
            results and draw conclusions therefrom. If you consent to receive
            marketing information when/where prompted, then we use your data to
            send you promotional material(s) and other communication and
            administer your participation in, special events, contests,
            sweepstakes, programs, offers, surveys and market research, to
            provide advertisements to you about our products and services, to
            tailor your use of the Website, marketing experience, and
            communications according to your preferences and our terms If you
            interact with us on third party social networks, then we process
            your data for such interaction with you on these networks involved
            at the time of communication/interaction between two parties. When
            you contact us through a third-party social network, you hereby
            acknowledge that you shall also be subject to the terms of usage and
            privacy policy enforced by the said network. (For example, if you
            reach out to us on Facebook, then you shall also be subject to the
            terms of use and privacy policy of Facebook) We process your
            personal data also to comply with and enforce applicable legal
            requirements, our Terms of Use, relevant industry standards,
            contractual obligations and our policies. We process metadata to
            improve our Website, analyze traffic pattern and Website usage, for
            targeted marketing, to develop and analyze statistics and
            demographics, or optimize our online presence and marketing efforts.
          </p>
          <h4 className={styles["title"]}>
            To whom do we disclose and transfer your personal data?
          </h4>
          <p className={styles["paragraph"]}>
            We may disclose your personal data to recipients or categories of
            recipients for them to use the data on their own behalf and under
            their own control (controllers): Our affiliates within i4Sight
            Technologies Group / joint venture partners in the countries where
            our services are offered or represented as indicated when we collect
            your personal data. We may disclose your personal data to the
            following recipients or categories of recipients acting on our
            behalf and/or as partners (processors), limited to the purpose of
            the execution of their obligations, which are contractually bound to
            adhere to an adequate level of data protection when processing your
            personal data (for e.g., fulfil orders, process credit card
            payments, provide customer service, send postal mail and e-mail,
            store and process data, host websites, remove repetitive data from
            customer lists, analyze data, provide marketing assistance, execute
            customer satisfaction surveys, provide customized advertising):
            i4Sight Technologies / Towerbuddy Our data processing center in
            India / webhosting service providerâ€™s location. Our affiliates
            within i4Sight Technologies / Towerbuddy and joint venture partner
            Group; Our third party service providers / partners as and if
            required. We may disclose your personal data to an acquirer if
            I4Sight Technologies / Towerbuddy or substantially all of its assets
            are acquired by a third party, in which case personal data held by
            it about its customers will be one of the transferred assets. We may
            disclose your personal data if we are under a duty to disclose or
            share your personal data in order to comply with any legal
            obligation, or in order to enforce or apply our terms of use and
            other agreements; or to protect the rights, property, or safety of
            I4Sight Technologies / Towerbuddy or any member of i4Sight
            Technologies / Tower group of companies or joint venture partners ,
            our customers, or others. This includes exchanging data with other
            companies and organizations for the purposes of fraud protection and
            credit risk reduction. We may internationally transfer your personal
            data, including to countries that are not considered providing an
            adequate level of data protection by the relevant regulatory bodies,
            for example to countries not considered by Indian / UAE Data
            Protection and Information Authorities to be providing such level.
            In such case, we ensure the adequate protection of your personal
            data by having the recipients adhere to binding contractual
            obligations in accordance with applicable standards approved by the
            relevant regulatory bodies or by relying on other safeguards, such
            as self-certifications, approved by the relevant regulatory bodies.
            You may contact us for a copy of the contractual and other
            safeguards in place (see section 9 below). This Website may contain
            links to and from the websites of our partner networks, advertisers
            and affiliates. If you follow a link to any of these websites,
            please note that they have their own privacy policies and that we do
            not accept any responsibility or liability for these policies.
            Please check these policies before you submit any personal data to
            these websites.
          </p>
          <h4 className={styles["title"]}>
            For how long do we process your personal data?
          </h4>
          <p className={styles["paragraph"]}>
            We process your personal data: until you withdraw your consent for
            future processing, for example until you unsubscribe from our
            newsletter or delete your account with us; until we are sure that
            you are satisfied after you have approached us, e.g. to order a
            service, to ask a question, to request information, to make an
            appointment or a reservation, etc. but no longer than twelve (12)
            months after the last contact, unless we can rely on another
            justification, have informed you otherwise or you have given your
            consent for a longer retention period; in connection with a
            transaction you make or in connection with a customer service
            transaction for the time until the transaction is completed plus at
            least the warranty period to which we may add a grace period for
            your benefit, unless we can rely on another justification, have
            informed you otherwise or you have given your consent for a longer
            retention period; for as long as you remain our business partner
            plus ten years, unless we can rely on another justification, have
            informed you otherwise or you have given your consent for a longer
            retention period; for as long as laws require us, e.g. legal
            retention obligations based on bookkeeping or tax laws and
            regulations.
          </p>
          <h4 className={styles["title"]}>Do we require your personal data?</h4>
          <p className={styles["paragraph"]}>
            If you wish to conclude a contract with us, for example if you order
            items or services, then we are required to obtain from you certain
            personal data to allow us to enter into this contract, for example,
            your name, business address, place of residence, payment information
            or contact information. Should you decide not to provide us the
            required information, we may not conclude this contract with you.
          </p>
          <h4 className={styles["title"]}>Can you contact us?</h4>
          <p className={styles["paragraph"]}>
            For any questions or to exercise your rights, you may contact us as
            follows: Contact us: DTEC, Silicon Oasis Dubai, UAE For the contact
            details of our representatives or DPO respectively within the
            jurisdiction, please see our Representatives List. Follow us:
            <a
              href="https://www.linkedin.com/company/i4sight-technologies/"
              target="_blank"
              rel="norefferrer"
              className={styles["link"]}
            >
              LinkedIn
            </a>
          </p>
          <h4 className={styles["title"]}>Cookies</h4>
          <p className={styles["paragraph"]}>
            Our site uses cookies to keep track of your session information. We
            do link the information we store in cookies to personally
            identifiable information you submit while on our site. We use
            cookies to store username if requested to have username remembered
            during login. That information will be used to pre-fill the login
            form at a later time. The use of cookies by our partners,
            affiliates, tracking utility company and service providers is not
            covered by our privacy statement. We do not have access or control
            over these cookies. Our tracking utility company uses session ID
            cookies to help us improve our site and make your browsing and
            purchasing experiences more enjoyable.
          </p>
        </Container>
      </section>
    </>
  );
}
