import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Container from "../UI/Container";
import styles from "./TermsOfUse.module.css";

export default function Privacy() {
  return (
    <>
      <section id="Privacy-policy">
        <Container className="container">
          <h3 className={styles["section-title"]}>Terms Of Use</h3>
          <h4 className={styles["title"]}>Introduction</h4>
          <div className={styles["paragraph"]}>
            <ul className={styles["lists"]}>
              <li className={styles["list-item"]}>
                <p>
                  Read this document ("Terms of Use") carefully before using the
                  platform ("Website / mobile application"). By accessing/using
                  the website / mobile application, you hereby accept and agree
                  to comply with the terms of use of the platform laid out in
                  this document. If that is not the case, do not use the
                  Website.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  Both the website along with the mobile application (app) are
                  the property of i4Sight Technologies / Towerbuddy, ("i4Sight
                  Technologies / Towerbuddy", "We", "Us", "Our") and are to be
                  used in accordance with the terms of usage in this document.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  i4Sight Technologies / Towerbuddy may at any time revise these
                  Terms of Use. You are bound to comply and accept the said
                  revision(s) and should therefore periodically visit this page
                  to review the then current terms to which you are bound.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  This document and the privacy policy is an electronic record
                  in terms of the Information Technology Act, 2000 of the Indian
                  constitution, and rules there under as applicable and the
                  amended provisions pertaining to electronic records in various
                  statutes as amended by the Information Technology Act, 2000.
                  These "Terms of Use" constitute an electronic record within
                  the meaning of the applicable law and is generated by a
                  computer system which does not require any physical or digital
                  signatures.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  To whom does this policy apply, Some parts of this Policy are
                  directed at specific types of people. We use industry specific
                  terminology to refer to them, which is defined below. This
                  policy applies to everyone who uses our services, but
                  sometimes we direct parts of it towards groups of users.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  To whom does this policy apply, Some parts of this Policy are
                  directed at specific types of people. We use industry specific
                  terminology to refer to them, which is defined below. This
                  policy applies to everyone who uses our services, but
                  sometimes we direct parts of it towards groups of users.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  Landlord/Real-estate agents: The owner(s) or representative(s)
                  of the person who owns the land.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  Operators / Telcos / MVNOs: Mobile / Virtual network operators
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  Tower companies: Organisations/companies that set up and
                  commission the mobile towers passive infrastructure required
                  for a functional cellular network.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  Legal consultants: For verifying the land documentations,
                  contract negotiations etc.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  ESCOs: Organisations/companies that set up and commission the
                  electricity power supply equipment for mobile towers passive
                  infrastructure to function as a cellular network.
                </p>
              </li>
              <li className={styles["list-item"]}>
                <p>
                  OEMs: Original Equipment Manufacturing Organisations/companies
                  that manufacture, supply, install, commission, operate and
                  maintain equipment at mobile tower sites that may be required
                  for a functional cellular network.
                </p>
              </li>
            </ul>
          </div>
          <h4 className={styles["title"]}>
            Purpose and contents of the website
          </h4>
          <p className={styles["paragraph"]}>
            All information and materials on the website / mobile application
            are presented solely for the purpose of providing general
            information on and promoting products and services of i4Sight
            Technologies / Towerbuddy. Such information and materials do not
            constitute offers for products and / or services of i4Sight
            Technologies / Towerbuddy or official retailers or other third
            parties, nor do they constitute advice or other instructions on the
            use of products and / or services of i4Sight Technologies /
            Towerbuddy. The Website / mobile application may contain information
            on our products and services in select geographies, not all of which
            are available at every location. A reference to an i4Sight
            Technologies / Towerbuddy product or service does not imply that
            that product or service is or will be available at your location.
          </p>
          <h4 className={styles["title"]}>User content</h4>
          <p className={styles["paragraph"]}>
            Please be aware that whenever you post information on the
            website/app that information may be accessible for other registered
            users on the platform. You should therefore exercise caution about
            the information you post, that is visible to others. i4Sight
            Technologies / Towerbuddy does not claim any ownership rights in the
            text, files, images, photos, videos, sounds, works of authorship or
            any other copyrighted/registered/trademarked materials that you may
            post ("User Content") on the website. As a user, you shall retain
            all rights to the content you have posted and will also continue to
            retain all rights of ownership and usage in any way or form chosen.
            After posting your User Content, you continue to retain all
            ownership rights in it, and you continue to have the right to use it
            in any way you choose. By posting your User Content on or through
            the Website, you hereby grant Us a non-exclusive, sublicensable,
            transferable, free and worldwide license to use, modify, publicly
            perform, publicly display, reproduce, and distribute your User
            Content on and through the Website or in the i4Sight Technologies /
            Towerbuddy section of a third-party platform (Facebook, Twitter,
            etc.) until you remove your User Content from the Website. (Requires
            a second look). You represent and warrant that: (i) you own the User
            Content posted by you on the Website or otherwise have the right to
            grant the license set forth above, and (ii) the posting of your User
            Content on the Website does not violate the privacy rights,
            publicity rights, copyrights, trademarks, contract rights or any
            other rights of any person or company. You are solely responsible
            for the User Content that you post on the Website. You may not post,
            transmit, or share User Content on the Website that you did not
            create or that you do not have permission to post. User Content is
            not necessarily reviewed by i4Sight Technologies / Towerbuddy prior
            to posting and does not necessarily reflect the opinions or policies
            of i4Sight Technologies / Towerbuddy. i4Sight Technologies /
            Towerbuddy makes no warranties, expressed or implied, as to User
            Content or the accuracy and reliability thereof or any material or
            information that you transmit to other users. You are prohibited
            from posting or transmitting any unlawful, threatening, libelous,
            defamatory, obscene, scandalous, inflammatory, pornographic or
            profane material or any material that could constitute or encourage
            conduct that would be considered a criminal offence, give rise to
            civil liability or otherwise violate any law. The Website may offer
            the possibility for users to create a personal account by certain
            information. In such cases, you will be required to choose an email
            address that you control and use regularly along with a suitable
            password. You are entirely responsible for maintaining the
            confidentiality of your password. Towerbuddy or its employees will
            not be able to recover your account passwords in any circumstances
            due to the sensitivity of the data involved. You also agree not to
            use the account, email address, or password of another user at any
            time or to disclose your password to any third party. You agree to
            notify us immediately if you suspect any unauthorized use of your
            account or access to your password. You are solely responsible for
            all use of your account. i4Sight Technologies / Towerbuddy expressly
            reserves the right to remove any information or materials posted or
            transmitted by any party/parties which is in breach of these rules,
            including any offers for i4Sight Technologies / Towerbuddy will
            fully cooperate with any law enforcement authorities or court order
            requesting or directing i4Sight Technologies / Towerbuddy to
            disclose the identity of anyone posting any such information or
            materials.
          </p>
          <h4 className={styles["title"]}>Intellectual property rights</h4>
          <p className={styles["paragraph"]}>
            The website and the mobile app, together with any information, text,
            images, photographs, audio and video clips, designs, graphics,
            logos, symbols, names, product and corporate designations, as well
            as all software contained in the website and all other files, and
            their selection and arrangement ("the Website Content"), are subject
            to legal protection, in particular copyright, trademarks and design
            rights held by i4Sight Technologies / Towerbuddy, its affiliates,
            its users or its licensors with all rights reserved. No Website
            Content may be modified, copied, distributed, framed, reproduced,
            republished, downloaded, displayed, posted, transmitted or sold in
            any form or by any means, in whole or in part, without our prior
            written permission, except that the foregoing does not apply to your
            own User Content that you legally post on the Website. In addition,
            no digital content whatsoever may be copied into other programs
            without the prior written approval of i4Sight Technologies /
            Towerbuddy. Provided that you are eligible for use of the Website,
            you are granted a limited license to access and use the Website and
            the Website Content and to download or print a copy of any portion
            of the Website Content to which you have properly gained access
            solely for your personal, non-commercial use, provided that you keep
            all copyright or other proprietary notices intact. The license is
            subject to the Terms of Use and does not include use of any data
            mining, robots or similar data gathering or extraction methods.
            Except for your own User Content, you may not upload or republish
            the Website Content on any Internet, intranet or extranet site(s) or
            incorporate it in any other database(s) or compilation(s), and any
            other use of the Website Content is strictly prohibited. Any use of
            the Website or the Website Content other than as specifically
            authorized herein, without the prior written permission of i4Sight
            Technologies / Towerbuddy, is strictly prohibited and will terminate
            the license granted herein. Any unauthorized use may also violate
            applicable laws, including without limitation copyright and
            trademark laws and applicable communications regulations and
            statutes. Unless explicitly stated herein, nothing in the Terms of
            Use will be construed as conferring any license to any intellectual
            property rights. "i4Sight Technologies / Towerbuddy" and other
            trademarks, logos, characters, page headers, button icons, scripts,
            service names and other marks (collectively "Trademarks") displayed
            on the Website are subject to the trademark and other rights of
            i4Sight Technologies / Towerbuddy or its affiliates. Our Trademarks
            may not be used, including as part of trademarks and/or as part of
            domain names, in connection with any product or service in any
            manner that is likely to cause confusion and may not be copied,
            imitated or used, in whole or in part, without the prior written
            permission of i4Sight Technologies / Towerbuddy. Your use / misuse
            of Trademarks displayed on the Website, or of any other Website
            Content, except as provided for in the Terms of Use, is strictly
            prohibited.
          </p>
          <h4 className={styles["title"]}>Disclaimer</h4>
          <p className={styles["paragraph"]}>
            While i4Sight Technologies / Towerbuddy uses reasonable efforts to
            include accurate and up-to-date information on the Website, it makes
            no warranties or representations with respect to the Website
            Content, which is provided "as is." In particular, i4Sight
            Technologies / Towerbuddy neither warrants nor represents that your
            use of the Website Content will not infringe the rights of third
            parties not owned by or affiliated with i4Sight Technologies /
            Towerbuddy. i4Sight Technologies / Towerbuddy assumes no
            responsibility, and shall not be liable for, any damage to, or
            viruses that may infect, your computer equipment or other property
            on account of your access to, use of or browsing of the Website or
            your downloading of any materials, data, text, images, video or
            audio from the Website. i4Sight Technologies / Towerbuddy reserves
            the right to interrupt or discontinue any or all of the
            functionalities of the Website. i4Sight Technologies / Towerbuddy
            accepts no responsibility or liability whatsoever for any
            interruption or discontinuance of any or all functionalities of the
            Website, irrespective of whether this is the result of actions or
            omissions of i4Sight Technologies / Towerbuddy or any of its
            affiliates or any third party. The Website Content may contain
            technical inaccuracies or typographical errors. i4Sight Technologies
            / Towerbuddy reserves the right to make changes, corrections and/or
            improvements to the Website Content at any time without notice.
          </p>
          <h4 className={styles["title"]}>Links to and from other websites</h4>
          <p className={styles["paragraph"]}>
            Because i4Sight Technologies / Towerbuddy has no control over and
            does not endorse any of the sites to which the Website is linked and
            because i4Sight Technologies / Towerbuddy has not reviewed any or
            all of the sites to which the Website is linked, you acknowledge
            that i4Sight Technologies / Towerbuddy is not responsible for the
            content of any off-site pages or any other sites linked to the
            Website. Responsibility for access to off-site pages and linked
            third-party sites rests solely with you. Your linking to the
            Website, off-site pages or other sites is at your own risk. By
            making use of any such links, you understand that the legal
            statement and privacy policy of any linked site will apply, which
            may be different from those of i4Sight Technologies / Towerbuddy. If
            you would like to link to the Website, you may only do so on the
            basis that you link to, but do not replicate, the home page, and
            subject to the following conditions. Framing of the Website by sites
            or site elements of parties not part of i4Sight.net / Towerbuddy.tel
            group is not permitted. In-line linking or any other manner of
            incorporating parts of the Website on sites of parties not part of
            i4Sight Technologies / infra4Sight Technologies is equally
            prohibited.
          </p>
          <h4 className={styles["title"]}>Service access</h4>
          <p className={styles["paragraph"]}>
            While i4Sight Technologies / Towerbuddy endeavors to ensure that the
            Website is normally available 24 hours a day, it will not be liable
            if for any reason it is unavailable at any time or for any period.
            Access to the Website may be suspended temporarily and without
            notice in case of a system failure, maintenance or repair or for
            reasons beyond Our control.
          </p>
          <h4 className={styles["title"]}>Limitation of liability</h4>
          <p className={styles["paragraph"]}>
            In as much as permitted by law, and except for damage caused by
            intent or gross negligence, i4Sight Technologies / Towerbuddy, and
            any other company of i4Sight Technolgies group, including the
            officers, directors, employees, shareholders or agents of any of
            them, exclude all liability and responsibility for any amount or
            kind of loss or damage that may result to you or a third party
            (including without limitation, any direct, indirect, or
            consequential loss or damage, or any loss of income, profits,
            goodwill or data) in connection with the Website & Mobile
            application in any way or in connection with the use, inability to
            use or the results of use of the Website and mobile application, any
            websites linked to the Website and mobile application or the
            material on such websites and mobile applications, including but not
            limited to loss or damage due to viruses that may infect your
            computer equipment, software, data or other property on account of
            your access to, use of or browsing of the Website or your
            downloading of any material from the Website or any websites linked
            to the Website and mobile application.
          </p>
          <h4 className={styles["title"]}>Privacy</h4>
          <p className={styles["paragraph"]}>
            We care about your privacy and the protection of your personal data.
            Read our
            <a
              href="https://www.linkedin.com/company/i4sight-technologies/"
              target="_blank"
              rel="norefferrer"
              className={styles["link"]}
            >
              privacy policy.
            </a>
          </p>
          <h4 className={styles["title"]}>Miscellaneous</h4>
          <p className={styles["paragraph"]}>
            The failure of i4Sight Technologies / Towerbuddy to exercise or
            enforce any right or provision of the Terms of Use will not operate
            as a waiver of any such right or provision. The section titles in
            the Terms of Use are for convenience only and have no legal or
            contractual effect. The invalidity or unenforceability of any
            provision of the Terms of Use does not affect or impair in any way
            the validity, legality and enforceability of the remaining
            provisions contained herein. The Terms of Use are governed by and
            will be interpreted in accordance with Indian law, without regard to
            any conflict-of-law rules or the United Nations Convention on
            Contracts for the International Sale of Goods. To the extent
            permitted by law, the competent courts at the place of the
            registered office of i4Sight Technologies / Towerbuddy, currently
            Bangalore / Dubai, will have exclusive jurisdiction in respect of
            any disputes arising from the Terms of Use and your use of the
            Website, subject to Our right to prosecute a user at his/her place
            of residence. If you have any questions about the Terms of Use,
            write to info@i4sight.net
          </p>
        </Container>
      </section>
    </>
  );
}
