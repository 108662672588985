import one from "../../assets/icons/1.png";
import two from "../../assets/icons/2.png";
import three from "../../assets/icons/3.png";
import four from "../../assets/icons/4.png";
import focus from "../../assets/icons/card_focus.png";
import strategy from "../../assets/icons/card_strategy.png";
import vision from "../../assets/icons/card_vision.png";
import CardAbout from "../UI/CardAbout";
import Container from "../UI/Container";
import styles from "./AboutUs.module.css";
import { useMediaQuery } from "react-responsive";

export default function AboutUs() {
  const isMobileOn = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <section id="About" className="about-us">
      <Container className="container">
        <h3 className={styles["section-title"]}>About Us</h3>
        <div className={styles["container-secondary"]}>
          <div className={styles["card-container"]}>
            {isMobileOn ? (
              <>
                <div className={styles.row}>
                  <div className={styles["container-image"]}>
                    <img
                      className={styles["card-number-image"]}
                      src={one}
                      alt="one number"
                    />
                  </div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is a first-of-its-kind B2B2C platform as a service
                      model with the concept of supporting the “Value for Money
                      and Value for Many” model to manage your assets and
                      resources effectively and efficiently.
                    </p>
                  </CardAbout>
                </div>
                <div className={styles.row}>
                  <div className={styles["container-image"]}>
                    <img
                      className={styles["card-number-image"]}
                      src={two}
                      alt="two number"
                    />
                  </div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is building an intuitive system that provides a
                      platform for all stakeholders to collaborate for
                      efficiency with visibility, and flexibility yet with
                      much-needed control over your assets to manage them
                      efficiently.
                    </p>
                  </CardAbout>
                </div>
                <div className={styles.row}>
                  <div className={styles["container-image"]}>
                    <img
                      className={styles["card-number-image"]}
                      src={three}
                      alt="three number"
                    />
                  </div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight’s deliverables include time-tested processes to
                      deliver best-in-class efficiency at optimized costs and
                      the best utilization of your prized assets with minimal
                      changes to your existing infrastructure.
                    </p>
                  </CardAbout>{" "}
                </div>
                <div className={styles.row}>
                  <div className={styles["container-image"]}>
                    <img
                      className={styles["card-number-image"]}
                      src={four}
                      alt="four number"
                    />
                  </div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is being built as a web-based, user-friendly
                      service with suitable mobile apps your teams can use on
                      the go!
                    </p>
                  </CardAbout>
                </div>
              </>
            ) : (
              <>
                <div className={styles.row}>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is a first-of-its-kind B2B2C platform as a service
                      model with the concept of supporting the “Value for Money
                      and Value for Many” model to manage your assets and
                      resources effectively and efficiently.
                    </p>
                  </CardAbout>
                  <div className={styles.line}></div>
                  <img
                    className={styles["card-number-image"]}
                    src={one}
                    alt="one number"
                  />
                </div>
                <div className={styles.row}>
                  <img
                    className={styles["card-number-image"]}
                    src={two}
                    alt="two number"
                  />
                  <div className={styles.line}></div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is building an intuitive system that provides a
                      platform for all stakeholders to collaborate for
                      efficiency with visibility, and flexibility yet with
                      much-needed control over your assets to manage them
                      efficiently.
                    </p>
                  </CardAbout>
                </div>
                <div className={styles.row}>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight’s deliverables include time-tested processes to
                      deliver best-in-class efficiency at optimized costs and
                      the best utilization of your prized assets with minimal
                      changes to your existing infrastructure.
                    </p>
                  </CardAbout>
                  <div className={styles.line}></div>
                  <img
                    className={styles["card-number-image"]}
                    src={three}
                    alt="three number"
                  />
                </div>
                <div className={styles.row}>
                  <img
                    className={styles["card-number-image"]}
                    src={four}
                    alt="four number"
                  />
                  <div className={styles.line}></div>
                  <CardAbout>
                    <p className={styles["about-card-content"]}>
                      i4Sight is being built as a web-based, user-friendly
                      service with suitable mobile apps your teams can use on
                      the go!
                    </p>
                  </CardAbout>
                </div>
              </>
            )}
          </div>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={focus} alt="focus icon" />
              <h4>Focus</h4>
              <p className={styles["about-card-content-secondary"]}>
                The focus of i4Sight is to provide solutions and develop systems
                for Industries to manage their Infrastructure optimally,
                enabling healthy, sustainable improvements to their bottom line
                and operational efficiencies.
              </p>
            </div>
            <div className={styles.card}>
              <img src={strategy} alt="strategy icon" />
              <h4>Strategy</h4>
              <p className={styles["about-card-content-secondary"]}>
                The strategy is to enable Decision Makers to have Frequently
                Needed Key Enabling Numbers on the go to enable their decisions
                for significant opportunities to meet ever-changing growth
                targets.
              </p>
            </div>
            <div className={styles.card}>
              <img src={vision} alt="vision icon" />
              <h4>Vision</h4>
              <p className={styles["about-card-content-secondary"]}>
                i4Sight's solutions are designed to improve productivity across
                all stages of the value chain as well as enhance timely
                information flow across the entire hierarchy of an organization.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
