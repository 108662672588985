import styles from "./Footer.module.css";
import logo from "../../assets/logo/i4S_logo.png";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentTime = new Date();
  const year = currentTime.getFullYear();

  return (
    <footer>
      <section className={styles.footer}>
        <div className={styles["container"]}>
          <div className={styles["container-secondary"]}>
            <div className={styles["grid-cols-4"]}>
              <div className={styles["logo-col"]}>
                <Link
                  className={styles["footer-logo"]}
                  to="/"
                  onClick={() => {
                    document.getElementById("Home").scrollIntoView();
                    window.scroll(0, -200);
                  }}
                >
                  <img src={logo} alt="I4S company logo" />
                </Link>
                <address>
                  <p className={styles.address}>
                    DTEC, Silicon Oasis,
                    <br />
                    Dubai UAE
                  </p>
                  <div className={styles["number"]}>
                    <a className={styles["nav-link"]} href="tel:+97143793299">
                      +971-43-793-299
                    </a>
                  </div>
                  <div className={styles["e-mail"]}>
                    <a
                      className={styles["nav-link"]}
                      href="mailto:info@i4shight.net"
                      target="_blank"
                      rel="norefferrer"
                    >
                      info@i4shight.net
                    </a>
                  </div>
                </address>
                <div className={styles["footer-social-links"]}>
                  <a className={styles["nav-link"]} href="#" target="_blank">
                    <FaLinkedin className={styles["icon"]} />
                  </a>
                  <a className={styles["nav-link"]} href="#" target="_blank">
                    <FaFacebookSquare className={styles["icon"]} />
                  </a>

                  <a className={styles["nav-link"]} href="#" target="_blank">
                    <FaTwitterSquare className={styles["icon"]} />
                  </a>
                  <a className={styles["nav-link"]} href="#" target="_blank">
                    <FaInstagramSquare className={styles["icon"]} />
                  </a>
                </div>
              </div>
              <div className={styles["quick-links"]}>
                <ul>
                  <li>
                    <p>Quick Links</p>
                  </li>
                  <li>
                    <Link
                      className={styles["nav-link"]}
                      to="/#Home"
                      onClick={() => {
                        setTimeout(function () {
                          document.getElementById("Home").scrollIntoView();
                          window.scroll(0, -200);
                        }, 10);
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={styles["nav-link"]}
                      to="/#About"
                      onClick={() => {
                        setTimeout(function () {
                          document.getElementById("About").scrollIntoView();
                        }, 10);
                      }}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={styles["nav-link"]}
                      to="/#Services"
                      onClick={() => {
                        setTimeout(function () {
                          document.getElementById("Services").scrollIntoView();
                        }, 10);
                      }}
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={styles["nav-link"]}
                      to="/#Contact"
                      onClick={() => {
                        setTimeout(function () {
                          document.getElementById("Contact").scrollIntoView();
                        }, 10);
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="our-services">
                <ul>
                  <li>
                    <p>Our Services</p>
                  </li>
                  <li>
                    <a className={styles["nav-link"]} href="#" target="_blank">
                      Realestate Management
                    </a>
                  </li>
                  <li>
                    <a className={styles["nav-link"]} href="#" target="_blank">
                      Active services Management
                    </a>
                  </li>
                  <li>
                    <a className={styles["nav-link"]} href="#" target="_blank">
                      Passive Services Management
                    </a>
                  </li>
                  <li>
                    <a className={styles["nav-link"]} href="#" target="_blank">
                      Energy Management
                    </a>
                  </li>
                </ul>
              </div>
              <div className="Credits">
                <ul>
                  <li>
                    <p>Credits</p>
                  </li>
                  <li>
                    <a
                      className={styles["nav-link"]}
                      href="http://www.freepik.com"
                      target="_blank"
                    >
                      Designed by stories / Freepik
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles["container-copyrights"]}>
              <div className={styles["copyrights"]}>
                <p>
                  &copy;Copyright {year} i4Sight Technologies. All Rights
                  Reserved
                </p>
              </div>
              <div className={styles["privacy-policy"]}>
                <Link
                  className={styles["nav-link"]}
                  to="/Privacy"
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and
                  }}
                >
                  Privacy Policy
                </Link>
                <span> | </span>
                <Link
                  className={styles["nav-link"]}
                  to="/Terms-of-Use"
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and
                  }}
                >
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}
