import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import LandingPage from "../LandingPage/LandingPage";
import ProductServices from "../ProductServices/ProductServices";
export default function RootElement() {
  return (
    <>
      <main>
        <LandingPage />
        <AboutUs />
        <ProductServices />
        <ContactUs />
      </main>
    </>
  );
}
