import towerBuddy from "../../assets/logo/tower_buddy.png";
import Container from "../UI/Container";
import styles from "./ProductServices.module.css";
import { RxOpenInNewWindow } from "react-icons/rx";

export default function ProductServices() {
  return (
    <section id="Services">
      <Container className="container">
        <h3 className={styles["section-title"]}>Product and Services</h3>
        <div
          onClick={() => {
            window.open("https://towerbuddy.tel/");
          }}
          className={styles["tower-buddy-container"]}
        >
          <div className={styles["container-logo"]}>
            <img src={towerBuddy} alt="tower buddy logo" />
          </div>
          <div className={styles["card-content"]}>
            <div className={styles["card-title"]}>
              <a href="https://towerbuddy.tel/">
                <h4>Tower Buddy</h4>
              </a>
              <RxOpenInNewWindow className={styles["card-icon"]} />
            </div>
            <p>
              Towerbuddy is our first offering to cater for telecom passive
              infrastructure management.
            </p>
          </div>
        </div>
        <div className={styles["services-cards"]}>
          <div className={styles["service-card"]}>
            <h4>Real Estate Management</h4>
            <p>
              Efficient, effective & lease lifecycle management for telecom real
              estate management is crucial for telecom companies to expand their
              network coverage, enhance service quality, and meet the increasing
              demands of customers for reliable connectivity. Involves
              maintaining positive relationships with property owners and
              adhering to legal and regulatory requirements.
            </p>
          </div>
          <div className={styles["service-card"]}>
            <h4>Energy Management</h4>
            <p>
              Efficient energy management reduces operational costs, supports
              telecom companies demonstrate their commitment to sustainability
              and environmental responsibility, which can be a competitive
              advantage in an increasingly environmentally conscious world
            </p>
          </div>

          <div className={styles["service-card"]}>
            <h4>Managed Services – Passive</h4>
            <p>
              Supports to focus on their core competencies of network planning,
              marketing, and customer service, while relying on specialized
              service providers for passive infrastructure management. This
              approach leads to cost savings, increased efficiency, and improved
              service quality as a service provider we bring in our expertise
              and experience in managing passive telecom infrastructure online
            </p>
          </div>
          <div className={styles["service-card"]}>
            <h4>Active Services Management</h4>
            <p>
              Specialized active services management allows telecom companies to
              leverage the expertise and resources of built for purpose service
              providers, which can result in improved network performance,
              better overall service quality for customers. Offloading the
              management of active network infrastructure, telecom companies can
              focus on their core business activities and strategic initiatives
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
