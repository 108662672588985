import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import { Routes, Route } from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import RootElement from "./components/RootElement/RootElement";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

export default function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<RootElement />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </>
  );
}
